import { useAuth } from '../../utils/auth';
import { AppMain } from '../../components/app-main/AppMain';
import { wrapAsync } from '../../utils/miscellaneous';
import { useObservableState, useSubscription } from 'observable-hooks';
import {
    loggedInUserDetails$,
    logoutFromAppAction$,
    stopImpersonationAction$,
    userLoggedOutFromApp$,
} from '../../store/user';
import { useBeforeunload } from 'react-beforeunload';
import { useLocation, useNavigate } from 'react-location';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { getLoggedInUserDetailsAndInitialize } from '../../data/user';
import { VerifyAccountDialog } from '../../components/onboarding/VerifyAccountDialog';
import { isAccountVerified } from '../../utils/account';
import {
    configTopic$,
    sessionExpired$,
    showSessionExpiredWarning$,
    loadTokens$,
    featureFlagsTopic$,
} from '../../store/config';
import { hotjar } from 'react-hotjar';
import { selectedWorkspace$ } from '../../store/workspace';
import { HtmlHead } from '../../components/htmlMeta/Head';
import { navigateAction$ } from '../../store/navigation';
import { workspaceHasUnsavedChanges } from '../../store/workspace/utils';
import { helpAndSupportModalOpen$, openHelpAndSupportModalAction$ } from '../../store/helpAndSupport';
import { themeMode$ } from '../../store/theme';
import { feedbackConnectionStatus$, notificationBannerDetails$ } from '../../store/feedback';
import { readLocalStorage, saveLocalStorage } from '../../utils/localStorage';
import { segmentAnalyticsTrack } from '../../data/segment-analytics';
import { segmentAndPendoAnalyticsTrack } from '../../data/analytics';
import { getBasePath, getPathName } from '../../utils/path';
import { selectedOrganizationUid$, switchOrganizationAction$ } from '../../store/organization';
import { openCreateOrganizationWizardDialogAction$ } from '../../store/organizations';
import { stitchThemeModeKey, ThemeMode } from '../../theme';
import {
    aiAssistanceAnswering$,
    aiAssistanceAvailableCredits$,
    aiAssistanceChat$,
    aiAssistanceGeneratedMessage$,
    aiAssistanceGetUsageDetailsAction$,
    aiAssistanceIsLongResponse$,
    aiAssistanceOnboardingCompletedAction$,
    aiAssistanceOpen$,
    aiAssistanceRateMessageAction$,
    aiAssistanceStreamAbortedAction$,
    closeAiAssistanceAction$,
    copyAiAssistanceCodeSnippetAction$,
    loadingAiAssistanceDialog$,
    openAiAssistanceAction$,
    resetAiAssistanceChatAction$,
    sendMessageToAiAssistanceAction$,
} from '../../store/workspace/ai-assistance';
import { AiChatResponseRating } from '@avst-stitch/repository-lib/lib/models';
import { createWorkspaceFromOnboardingTemplate$, onboardingTemplateUid$ } from '../../store/onboarding';

interface AppMainContainerProps {
    rootPath: string;
}

export const AppMainContainer: React.FC<AppMainContainerProps> = ({ rootPath }) => {
    const [retryBusy, setRetryBusy] = useState(false);
    const [logOutBusy, setLogOutBusy] = useState(false);
    const { operations } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    useSubscription(navigateAction$, (to) => navigate({ to }));

    useBeforeunload((event) => {
        if (workspaceHasUnsavedChanges()) {
            event.preventDefault();
            return 'Workspaces has unsaved changes, are you sure you want to quit?';
        }
    });

    useSubscription(switchOrganizationAction$, (uid) => {
        aiAssistanceGetUsageDetailsAction$.next(uid);
    });

    const loggedInUserDetails = useObservableState(loggedInUserDetails$);
    const sessionExpired = useObservableState(sessionExpired$);
    const showSessionExpiredWarning = useObservableState(showSessionExpiredWarning$);
    const config = useObservableState(configTopic$);
    const selectedWorkspace = useObservableState(selectedWorkspace$);
    const accountVerified = loggedInUserDetails?.accountVerified;
    const isConnectedToFeedback = useObservableState(feedbackConnectionStatus$);
    const notificationBanner = useObservableState(notificationBannerDetails$);
    const userLoggedOutFromApp = useObservableState(userLoggedOutFromApp$);
    const themeMode = useObservableState(themeMode$);
    const helpAndSupportOpen = useObservableState(helpAndSupportModalOpen$);
    const selectedOrganizationUid = useObservableState(selectedOrganizationUid$);
    const featureFlags = useObservableState(featureFlagsTopic$);
    const aiGeneratedAnswer = useObservableState(aiAssistanceGeneratedMessage$);
    const aiAssistanceChat = useObservableState(aiAssistanceChat$);
    const aiAssistanceLoading = useObservableState(loadingAiAssistanceDialog$);
    const aiAssistanceAnswering = useObservableState(aiAssistanceAnswering$);
    const aiAssistanceIsLongResponse = useObservableState(aiAssistanceIsLongResponse$);
    const aiAssistanceOpen = useObservableState(aiAssistanceOpen$);
    const aiAssistanceAvailableCredits = useObservableState(aiAssistanceAvailableCredits$);

    useEffect(() => {
        if (userLoggedOutFromApp === true) {
            setLogOutBusy(false);
            void operations.logout({ redirectUrl: window.location.origin });
        }
    }, [userLoggedOutFromApp]);

    useEffect(() => {
        if (loggedInUserDetails && loggedInUserDetails.uid) {
            if (config.analytics?.hotjar && hotjar.initialized()) {
                hotjar.identify(loggedInUserDetails.uid, {
                    uid: loggedInUserDetails.uid,
                });
            }
            if (config.analytics?.segment?.writeKey) {
                const isLoginEvent: boolean = readLocalStorage('isLoginEvent', false);

                if (isLoginEvent) {
                    if (loggedInUserDetails.publishUserRegisteredEvent) {
                        segmentAndPendoAnalyticsTrack('User Registered', {
                            userId: loggedInUserDetails.uid,
                            loggedInWithIdp: loggedInUserDetails.loggedInWithIdp,
                            stitchTeamMember: loggedInUserDetails.stitchTeamMember,
                            userOrigin: loggedInUserDetails.userOrigin,
                        });
                    }

                    localStorage.removeItem('isLoginEvent');
                    segmentAnalyticsTrack('User Logged In', {
                        userId: loggedInUserDetails.uid,
                        loggedInWithIdp: loggedInUserDetails.loggedInWithIdp,
                        stitchTeamMember: loggedInUserDetails.stitchTeamMember,
                        userOrigin: loggedInUserDetails.userOrigin,
                    });
                }
            }
        }
    }, [loggedInUserDetails?.uid]);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.current.pathname,
        });
    }, [location.current.pathname]);

    const handleLogOut = async (): Promise<void> => {
        setLogOutBusy(true);
        logoutFromAppAction$.next();
    };

    const handleRetry = async (): Promise<void> => {
        //TODO: change to resend email verification

        setRetryBusy(true);

        const response = await isAccountVerified();

        if (response.accountVerified) {
            setRetryBusy(false);
            const userDetails = await getLoggedInUserDetailsAndInitialize();
            loggedInUserDetails$.next(userDetails);
        } else {
            setRetryBusy(false);
        }
    };

    const handleChangeThemeMode = (mode: ThemeMode): void => {
        themeMode$.next(mode);
        saveLocalStorage(stitchThemeModeKey, mode);
    };

    const getTemplateUid = (): string => {
        const splitPath = window.location.pathname.split('/');
        const templateUidIndex = splitPath.findIndex((p) => p === 'template') + 1;
        return splitPath[templateUidIndex] ?? '';
    };

    // Going to be undefined in case of an impersonated user
    if (loggedInUserDetails && accountVerified === false) {
        return (
            <VerifyAccountDialog
                loading={retryBusy}
                onRetry={wrapAsync(handleRetry)}
                onCancel={wrapAsync(handleLogOut)}
            />
        );
    }

    const title = selectedWorkspace ? `ScriptRunner Connect - ${selectedWorkspace?.name}` : 'ScriptRunner Connect';

    const selectedOrganization = loggedInUserDetails?.organizations?.find((org) => org.uid === selectedOrganizationUid);

    const selectedPath = getPathName();
    const basePath = getBasePath();

    const setupGuide = window.location.pathname.split('/').includes('guide');
    const onboardingWizard = window.location.pathname.split('/').includes('onboarding');
    const fullScreenReadme = window.location.pathname.includes('/fullscreen-readme/');

    const isTemplateUrl = window.location.pathname.startsWith(`${getBasePath()}template/`);
    const templateUid = isTemplateUrl ? getTemplateUid() : undefined;
    const createWorkspaceFromOnboardingTemplate = isTemplateUrl
        ? window.location.search.includes('?create=true')
        : false;

    if (templateUid && !loggedInUserDetails?.onboardingCompleted) {
        onboardingTemplateUid$.next(templateUid);
        createWorkspaceFromOnboardingTemplate$.next(createWorkspaceFromOnboardingTemplate);
    }

    return (
        <>
            <HtmlHead name={title} />
            <AppMain
                basePath={basePath}
                displayApiKeys={!!featureFlags.apiKeys}
                displaySidenav={!setupGuide && !fullScreenReadme && !onboardingWizard}
                helpAndSupportOpen={helpAndSupportOpen}
                impersonating={loggedInUserDetails?.impersonating}
                isConnectedToFeedback={isConnectedToFeedback}
                isLogOutBusy={logOutBusy}
                notificationBanner={notificationBanner}
                onboardingCompleted={loggedInUserDetails?.onboardingCompleted}
                aiAssistanceOnboardingCompleted={loggedInUserDetails?.aiAssistanceOnboardingCompleted}
                organizations={loggedInUserDetails?.organizations ?? []}
                roleMissing={!loggedInUserDetails?.customRole && !loggedInUserDetails?.roleUid}
                rootPath={rootPath}
                scriptingFamiliarityMissing={!loggedInUserDetails?.scriptingFamiliarityUid}
                selectedOrganizationUid={selectedOrganizationUid}
                selectedPath={selectedPath}
                sessionExpired={sessionExpired}
                showSessionExpiredWarning={showSessionExpiredWarning}
                showUpgradePlan={selectedOrganization?.showUpgradePlan}
                themeMode={themeMode}
                userCredentials={{
                    firstName: loggedInUserDetails?.firstName ?? '',
                    lastName: loggedInUserDetails?.lastName ?? '',
                    email: loggedInUserDetails?.email ?? '',
                }}
                useRouter={true}
                onLogOut={wrapAsync(handleLogOut)}
                onChangeThemeMode={handleChangeThemeMode}
                onCreateNewOrganization={() => openCreateOrganizationWizardDialogAction$.next()}
                onManageAllOrganizations={() => navigate({ to: `${basePath}/teams` })}
                onOpenHelpAndSupport={() => {
                    openHelpAndSupportModalAction$.next();
                    segmentAnalyticsTrack('Connect & Support Accessed', {
                        userId: loggedInUserDetails?.uid,
                        stitchTeamMember: loggedInUserDetails?.stitchTeamMember,
                        userOrigin: loggedInUserDetails?.userOrigin,
                    });
                }}
                onSelectOrganization={(uid) => switchOrganizationAction$.next(uid)}
                onStopImpersonation={() => stopImpersonationAction$.next()}
                onSetLoadTokens={(loadTokens) => loadTokens$.next(loadTokens)}
                aiAssistanceOpen={aiAssistanceOpen}
                onToggleAiAssistant={() => {
                    if (aiAssistanceOpen) {
                        closeAiAssistanceAction$.next();
                    } else {
                        openAiAssistanceAction$.next();
                    }
                }}
                onCompleteAiOnboarding={() => aiAssistanceOnboardingCompletedAction$.next()}
                aiAssistanceAnswering={aiAssistanceAnswering}
                aiAssistanceChat={aiAssistanceChat}
                aiAssistanceLoading={aiAssistanceLoading}
                aiGeneratedAnswer={aiGeneratedAnswer}
                aiAssistanceIsLongResponse={aiAssistanceIsLongResponse}
                onResetAiAssistanceChat={() => resetAiAssistanceChatAction$.next()}
                onCopyAiCodeSnippet={() => copyAiAssistanceCodeSnippetAction$.next()}
                onCloseAiAssistance={() => closeAiAssistanceAction$.next()}
                onAskAiAssistance={(message) =>
                    sendMessageToAiAssistanceAction$.next({
                        // workspaceUid: '', // TODO: add logic, when user in a workspace
                        // environmentUid: '',
                        message,
                        organizationUid: selectedOrganizationUid ?? '',
                    })
                }
                onStopAiAssistanceResponse={() => aiAssistanceStreamAbortedAction$.next()}
                onRateAiAssistanceMessage={(request: { uid: string; rating: AiChatResponseRating }) =>
                    aiAssistanceRateMessageAction$.next(request)
                }
                aiAssistanceAvailableCredits={aiAssistanceAvailableCredits}
            />
        </>
    );
};

import { BehaviorSubject, debounceTime, map, Observable, Subject, tap } from 'rxjs';
import {
    selectedEnvironmentUid$,
    selectedWorkspaceEnvironment$,
    selectedWorkspaceEnvironments$,
    selectedWorkspaceManualScriptTriggerBundle$,
    selectedWorkspaceManualBundledScripts$,
    selectedWorkspaceResources$,
    selectedWorkspaceSelectedResource$,
    selectedWorkspaceUid$,
} from '.';
import {
    createScript,
    deleteScript,
    saveScript as saveScriptRpcCall,
    renameScript as renameScriptRpcCall,
    Script,
    ScriptsWithUrl,
    getScripts,
    getScript,
    ScriptWithUrl,
} from '../../data/script';
import { BundlingError, CompilationError, InformativeError } from '../../utils/error';
import { executeScript } from '../../utils/trigger';
import { promptQuestion } from '../confirm';
import { publishLocalFeedbackEventAction$ } from '../feedback';
import { monitor } from '../monitor';
import { DeletionRequest } from './types';
import { Position, ScriptPosition } from '../../components/workspace-resources/scripts/ScriptDetails';
import { currentTypeDeclarationsLoaded$, totalTypeDeclarationsLoaded$, renameEditorAction$ } from '../editor/editor';
import { wrapAsync } from '../../utils/miscellaneous';
import { saveScriptBundledOutput, ScriptWithUid } from '../../utils/bundler';
import { refetch } from '../../utils/fetch';
import { featureFlagsTopic$ } from '../config';

export const selectScriptAction$ = monitor(
    'selectScriptAction$',
    new Subject<{ scriptUid: string; environmentUid: string }>()
);
export const navigateToScriptAction$ = monitor('navigateToScriptAction$', new Subject<string>());
export const createNewScriptAction$ = monitor('createScriptAction$', new Subject<void>());
export const saveNewScriptAction$ = monitor(
    'saveNewScriptAction$',
    new Subject<{ name: string; forScheduledTrigger?: boolean }>()
);
export const cancelNewScriptAction$ = monitor('cancelNewScriptAction$', new Subject<void>());
export const newScriptCreatedAction$ = monitor('newScriptCreatedAction$', new Subject<{ uid: string }>());
export const saveScriptAction$ = monitor('saveScriptAction$', new Subject<void>());
export const renameScriptAction$ = monitor('renameScriptAction$', new Subject<string>());
export const selectedScriptContentChangedAction$ = monitor(
    'selectedScriptContentChangedAction$',
    new BehaviorSubject<string>('')
);
export const selectedScriptNameChangedAction$ = monitor(
    'unsavedScriptNameChangedAction$',
    new BehaviorSubject<string>('')
);
export const scriptNameChangedAction$ = monitor('scriptNameChangedAction$', new Subject<string>());
export const triggerScriptAction$ = monitor('triggerScriptAction$', new Subject<string>());
export const deleteScriptAction$ = monitor('deleteScriptAction$', new Subject<DeletionRequest>());
export const scriptDeletedAction$ = monitor('scriptDeletedAction$', new Subject<string>());
export const editorDisableReadOnlyMode$ = monitor('editorDisableReadOnlyMode$', new Subject<void>());
export const scriptCreatedForScheduledTriggerAction$ = monitor(
    'scriptCreatedForScheduledTriggerAction$',
    new Subject<{ uid: string; name: string }>()
);

export const newScriptDialogOpen$ = monitor('newScriptDialogOpen$', new BehaviorSubject(false));
export const savingNewScript$ = monitor('savingNewScript$', new BehaviorSubject(false));
export const newScriptErrors$ = monitor('newScriptErrors$', new BehaviorSubject<string | undefined>(undefined));
export const savedScriptDetails$ = monitor('savedScriptDetails$', new BehaviorSubject<Record<string, Script>>({}));
export const unsavedScriptDetails$ = monitor('unsavedScriptDetails$', new BehaviorSubject<Record<string, Script>>({}));
export const selectedScriptUid$ = monitor('selectedScriptUid$', new BehaviorSubject<string | null>(null));
export const scriptsBeingSaved$ = monitor('savingScript$', new BehaviorSubject<Record<string, boolean>>({}));
export const executingScript$ = monitor('executingScript$', new BehaviorSubject(false));
export const scriptHasUnsavedChanges$ = monitor(
    'scriptHasUnsavedChanges$',
    new BehaviorSubject<Record<string, boolean>>({})
);
export const scriptExecutionInProgress$ = monitor(
    'scriptExecutionInProgress$',
    new BehaviorSubject<Record<string, boolean>>({})
);
export const scriptsBeingDeleted$ = monitor('scriptsBeingDeleted$', new BehaviorSubject<Record<string, boolean>>({}));
export const editorReadOnly$ = monitor('editorReadOnly$', new BehaviorSubject(false));
export const userDisabledEditorReadOnly$ = monitor('userDisabledEditorReadOnly$', new BehaviorSubject(false));
export const scriptHelperPopupOpen$ = monitor('scriptHelperPopupOpen$', new BehaviorSubject(true));
export const scriptHelperPopupVisible$ = monitor('scriptHelperPopupVisible$', new BehaviorSubject(true));
export const externallyTriggerableScripts$ = monitor(
    'externallyTriggerableScripts$',
    new BehaviorSubject<string[]>([])
);
export const scriptDependants$ = monitor('scriptDependants$', new BehaviorSubject<Record<string, string[]>>({}));
export const scriptHasTriggerImport$ = monitor('scriptHasTriggerImport$', new BehaviorSubject(false));

export const editScriptNameDialogOpen$ = monitor('editScriptNameDialogOpen$', new BehaviorSubject(false));
export const editScriptNameDialogErrors$ = monitor(
    'editScriptNameDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const editScriptNameDialogLoading$ = monitor('editScriptNameDialogLoading$', new BehaviorSubject(false));
export const openEditScriptNameDialogAction$ = monitor('openEditScriptNameDialogAction$', new Subject<void>());
export const closeEditScriptNameDialogAction$ = monitor('closeEditScriptNameDialogAction$', new Subject<void>());
export const editScriptNameAction$ = monitor('editScriptNameAction$', new Subject<string>());

openEditScriptNameDialogAction$.subscribe(() => {
    editScriptNameDialogErrors$.next(undefined);
    editScriptNameDialogOpen$.next(true);
});
closeEditScriptNameDialogAction$.subscribe(() => editScriptNameDialogOpen$.next(false));

editScriptNameAction$
    .pipe(
        map(async (name) => {
            editScriptNameDialogLoading$.next(true);
            editScriptNameDialogErrors$.next(undefined);

            const otherNames = Object.values(savedScriptDetails$.value).map((script) => script.name);
            const scriptKey = `${selectedScriptUid$.value ?? ''}_${selectedEnvironmentUid$.value ?? ''}`;
            const selectedSavedScript = savedScriptDetails$.value[scriptKey];

            if (!otherNames.includes(name)) {
                const oldName = selectedSavedScript?.name;

                if (oldName && name && oldName !== name) {
                    renameEditorAction$.next({ name: oldName, newName: name });
                }
                selectedScriptNameChangedAction$.next(name);
                renameScriptAction$.next(name);
            } else {
                editScriptNameDialogErrors$.next(
                    'Failed to rename script, because script with such name already exists.'
                );

                editScriptNameDialogLoading$.next(false);
            }
        })
    )
    .subscribe();

selectScriptAction$.subscribe(({ scriptUid, environmentUid }) => {
    const scriptKey = `${scriptUid}_${environmentUid}`;
    const unsavedScriptDetails = unsavedScriptDetails$.value[scriptKey];
    if (unsavedScriptDetails) {
        selectedScriptUid$.next(scriptUid);
        selectedScriptNameChangedAction$.next(unsavedScriptDetails?.name);
        selectedScriptContentChangedAction$.next(unsavedScriptDetails?.content);
        selectedWorkspaceSelectedResource$.next({
            resourceType: 'SCRIPT',
            uid: scriptUid,
        });
    }
});

createNewScriptAction$.subscribe(() => {
    newScriptDialogOpen$.next(true);
    savingNewScript$.next(false);
    newScriptErrors$.next(undefined);
});

cancelNewScriptAction$.subscribe(() => newScriptDialogOpen$.next(false));

editorDisableReadOnlyMode$.subscribe(() => userDisabledEditorReadOnly$.next(true));

selectedScriptContentChangedAction$.subscribe((content) => {
    const scriptUid = selectedScriptUid$.value;
    if (scriptUid) {
        const selectedEnvironmentUid = selectedEnvironmentUid$.value;
        const scriptKey = `${scriptUid}_${selectedEnvironmentUid ?? ''}`;
        const unsavedScriptDetails = unsavedScriptDetails$.value[scriptKey];
        if (unsavedScriptDetails) {
            let otherHeadEnvironmentScriptDetails: Record<string, Script> = {};
            const selectedEnvironment = selectedWorkspaceEnvironment$.value;

            if (selectedEnvironment && !selectedEnvironment.deployment) {
                const otherHeadEnvironments = selectedWorkspaceEnvironments$.value
                    .filter((env) => !env.deployment && env.uid !== selectedEnvironment.uid)
                    .map((env) => env.uid);

                otherHeadEnvironmentScriptDetails = Object.fromEntries(
                    otherHeadEnvironments.map((env) => [`${scriptUid}_${env}`, { ...unsavedScriptDetails, content }])
                );
            }

            unsavedScriptDetails$.next({
                ...unsavedScriptDetails$.value,
                ...otherHeadEnvironmentScriptDetails,
                [scriptKey]: {
                    ...unsavedScriptDetails,
                    content,
                },
            });

            const savedScriptDetails = savedScriptDetails$.value[scriptKey];
            if (savedScriptDetails) {
                const missingOtherHeadEnvironmentSavedScriptDetails = Object.fromEntries(
                    Object.keys(otherHeadEnvironmentScriptDetails)
                        .filter((key) => !savedScriptDetails$.value[key])
                        .map((key) => [key, { ...savedScriptDetails }])
                );

                savedScriptDetails$.next({
                    ...savedScriptDetails$.value,
                    ...missingOtherHeadEnvironmentSavedScriptDetails,
                });
            }
        }
    }
});

selectedScriptNameChangedAction$.subscribe((name) => {
    if (selectedScriptUid$.value) {
        const selectedEnvironmentUid = selectedEnvironmentUid$.value;
        const scriptKey = `${selectedScriptUid$.value ?? ''}_${selectedEnvironmentUid ?? ''}`;
        const unsavedScriptDetails = unsavedScriptDetails$.value[scriptKey];
        if (unsavedScriptDetails) {
            unsavedScriptDetails$.next({
                ...unsavedScriptDetails$.value,
                [scriptKey]: {
                    ...unsavedScriptDetails,
                    name,
                },
            });
        }
    }
});

saveNewScriptAction$
    .pipe(
        map(async ({ name, forScheduledTrigger }) => {
            savingNewScript$.next(true);
            newScriptErrors$.next(undefined);

            try {
                const script = await createScript(selectedWorkspaceUid$.value ?? '', name);

                newScriptDialogOpen$.next(false);
                if (forScheduledTrigger) {
                    scriptCreatedForScheduledTriggerAction$.next({ uid: script.uid, name });
                } else {
                    newScriptCreatedAction$.next({ uid: script.uid });
                }

                selectedWorkspaceManualScriptTriggerBundle$.next(true);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Script created.',
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    newScriptErrors$.next(e.message);
                } else {
                    newScriptErrors$.next(
                        'Unknown error occurred while creating a new script. Please try again, if the issue persists please contact support.'
                    );
                    console.error('Error while creating new script', e);
                }
            }

            savingNewScript$.next(false);
        })
    )
    .subscribe();

deleteScriptAction$
    .pipe(
        map(async (event) => {
            const initiateScriptDelete = async (): Promise<void> => {
                scriptsBeingDeleted$.next({
                    ...scriptsBeingDeleted$.value,
                    [event.uid]: true,
                });

                try {
                    const response = await deleteScript(event.uid, event.ignoreWarnings);

                    if (!event.ignoreWarnings && response.warning) {
                        promptQuestion({
                            title: response.warning,
                            onProceed: () =>
                                deleteScriptAction$.next({
                                    ...event,
                                    ignoreWarnings: true,
                                }),
                        });
                    } else if (!response.warning) {
                        const scriptName = savedScriptDetails$.value[event.uid]?.name;
                        const otherScriptNames = Object.values(savedScriptDetails$.value)
                            .map((script) => script.name)
                            .filter((sn) => sn !== scriptName);

                        if (scriptName) {
                            deleteFolderExpandKeyIfRedundant(scriptName, otherScriptNames);
                        }

                        savedScriptDetails$.next(
                            Object.fromEntries(
                                Object.entries(savedScriptDetails$.value).filter(
                                    ([scriptKey]) => !scriptKey.startsWith(event.uid)
                                )
                            )
                        );
                        unsavedScriptDetails$.next(
                            Object.fromEntries(
                                Object.entries(unsavedScriptDetails$.value).filter(
                                    ([scriptKey]) => !scriptKey.startsWith(event.uid)
                                )
                            )
                        );

                        scriptDeletedAction$.next(event.uid);
                        selectedWorkspaceManualScriptTriggerBundle$.next(true);
                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Script deleted.',
                        });
                    }
                } catch (e) {
                    console.error('Error while deleting Script', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to delete Script, please try again, if the issue persists please contact support.`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }

                scriptsBeingDeleted$.next({
                    ...scriptsBeingDeleted$.value,
                    [event.uid]: false,
                });
            };

            if (!event.ignoreWarnings) {
                promptQuestion({
                    title: 'Are you sure you want to delete the Script?',
                    onProceed: async () => await initiateScriptDelete(),
                });
            } else {
                await initiateScriptDelete();
            }
        })
    )
    .subscribe();

saveScriptAction$
    .pipe(
        map(async () => {
            const scriptUid = selectedScriptUid$.value;
            if (scriptUid) {
                await saveScript(scriptUid, true);
            }
        })
    )
    .subscribe();

renameScriptAction$
    .pipe(
        map(async () => {
            const scriptUid = selectedScriptUid$.value;
            if (scriptUid) {
                await renameScript(scriptUid);
            }
        })
    )
    .subscribe();

selectedScriptContentChangedAction$
    .pipe(
        debounceTime(100),
        tap(() => updateScriptSavedStatus(selectedScriptUid$.value ?? ''))
    )
    .subscribe();

selectedScriptNameChangedAction$
    .pipe(
        debounceTime(100),
        tap(() => updateScriptSavedStatus(selectedScriptUid$.value ?? ''))
    )
    .subscribe();

newScriptCreatedAction$.subscribe(({ uid }) => {
    navigateToScriptAction$.next(uid);
    selectedWorkspaceManualScriptTriggerBundle$.next(true);
});

triggerScriptAction$
    .pipe(
        map(async (scriptUid) => {
            await triggerScript(selectedEnvironmentUid$.value ?? '', scriptUid);
        })
    )
    .subscribe();

const updateScriptSavedStatus = (scriptUid: string): void => {
    scriptHasUnsavedChanges$.next({
        ...scriptHasUnsavedChanges$.value,
        [scriptUid]: scriptHasUnsavedChanges(scriptUid),
    });
};

const scriptHasUnsavedChanges = (scriptUid: string): boolean => {
    const selectedEnvironmentUid = selectedEnvironmentUid$.value;
    const scriptKey = `${scriptUid}_${selectedEnvironmentUid ?? ''}`;
    return savedScriptDetails$.value[scriptKey]?.content !== unsavedScriptDetails$.value[scriptKey]?.content;
};

const updateScriptExecutionStatus = (scriptUid: string, isInProgress: boolean): void => {
    scriptExecutionInProgress$.next({
        ...scriptExecutionInProgress$.value,
        [scriptUid]: isInProgress,
    });
};

const getExternallyTriggerableScripts = (
    scriptUid: string,
    externallyTriggerableScripts: string[] = [],
    checked: string[] = []
): string[] => {
    if (checked.includes(scriptUid)) {
        return externallyTriggerableScripts;
    }

    const scriptHasTriggerImport = scriptHasTriggerImport$.value;
    if (scriptHasTriggerImport) {
        return selectedWorkspaceResources$.value.scripts.map((script) => script.uid).filter((uid) => uid !== scriptUid);
    }

    const dependantScripts = scriptDependants$.value[scriptUid];
    checked.push(scriptUid);
    if (dependantScripts) {
        for (const dependantScript of dependantScripts) {
            if (checked.includes(dependantScript)) {
                continue;
            }

            if (externallyTriggerableScripts$.value.includes(dependantScript)) {
                externallyTriggerableScripts.push(dependantScript);
            }
            const scripts = getExternallyTriggerableScripts(dependantScript, [], checked);
            externallyTriggerableScripts.push(...scripts);
        }
    }
    return externallyTriggerableScripts;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const saveScript = async (scriptUid: string, saveExternalDependants = false): Promise<void> => {
    const selectedEnvironmentUid = selectedEnvironmentUid$.value;
    const scriptKey = `${scriptUid}_${selectedEnvironmentUid ?? ''}`;
    const script = unsavedScriptDetails$.value[scriptKey];
    if (script) {
        try {
            scriptsBeingSaved$.next({
                ...scriptsBeingSaved$.value,
                [scriptUid]: true,
            });

            await saveScriptRpcCall(scriptUid, script.content);
            let externallyTriggerableScripts: string[] = [];
            if (saveExternalDependants) {
                externallyTriggerableScripts = Array.from(new Set(getExternallyTriggerableScripts(scriptUid)));
            }

            try {
                // Saving also bundled output in case the user wants to trigger the script externally while the environment has not yet been deployed
                await saveScriptBundledOutput(scriptUid, externallyTriggerableScripts);
            } catch (e) {
                if (e instanceof InformativeError) {
                    if (e instanceof CompilationError) {
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message:
                                "Failed to compile the script while saving, check the console for more errors, fix the errors and then try saving the script again. As a consequence triggering this script externally from an environment that has yet to be deployed won't be able to trigger the latest version until the errors have been fixed and the script is saved again.",
                        });
                    } else if (e instanceof BundlingError) {
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message:
                                "Failed to bundle the script while saving, check the console for more errors, fix the errors and then try saving the script again. As a consequence triggering this script externally from an environment that has yet to be deployed won't be able to trigger the latest version until the errors have been fixed and the script is saved again.",
                        });
                    } else {
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message: `Error while saving compiled version of the script: ${e.message}, fix the errors and then try saving the script again. As a consequence triggering this script externally from an environment that has yet to be deployed won't be able to trigger the latest version until the errors have been fixed and the script is saved again.`,
                        });
                    }
                } else {
                    console.error('Failed to save compiled version of the script while saving', e);
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Error occurred while saving compiled version of the script, please try saving the script again. As a consequence triggering this script externally from an environment that has yet to be deployed won't be able to trigger the latest version until the errors have been fixed and the script is saved again.`,
                    });
                }
            }

            savedScriptDetails$.next({
                ...Object.fromEntries(
                    Object.entries(savedScriptDetails$.value).filter(([key]) => !key.startsWith(scriptUid))
                ),
                [scriptKey]: {
                    name: script.name,
                    content: script.content,
                    // TODO: set last modified date and by whom
                },
            });
            unsavedScriptDetails$.next({
                ...Object.fromEntries(
                    Object.entries(unsavedScriptDetails$.value).filter(([key]) => !key.startsWith(scriptUid))
                ),
                [scriptKey]: {
                    name: script.name,
                    content: script.content,
                    // TODO: set last modified date and by whom
                },
            });

            updateScriptSavedStatus(scriptUid);
            selectedWorkspaceManualScriptTriggerBundle$.next(true);

            if (scriptUid === selectedScriptUid$.value) {
                selectedScriptContentChangedAction$.next(script.content);
            }
        } catch (e) {
            if (e instanceof InformativeError) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to save script: ${script.name}. Reason: ${e.message}`,
                });
            } else {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to save script: ${script.name}, try saving the script again, if the issue persists please contact support.`,
                    toastOptions: {
                        autoClose: false,
                    },
                });
                console.error('Error while creating new script', e);
            }
        }

        scriptsBeingSaved$.next({
            ...scriptsBeingSaved$.value,
            [scriptUid]: false,
        });
    }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const renameScript = async (scriptUid: string): Promise<void> => {
    const selectedEnvironmentUid = selectedEnvironmentUid$.value;
    const scriptKey = `${scriptUid}_${selectedEnvironmentUid ?? ''}`;
    const script = unsavedScriptDetails$.value[scriptKey];
    if (script) {
        try {
            await renameScriptRpcCall(scriptUid, script.name);

            const oldName = savedScriptDetails$.value[scriptKey]?.name;
            const otherScriptNames = Object.values(savedScriptDetails$.value)
                .map((script) => script.name)
                .filter((sn) => sn !== oldName);

            if (oldName) {
                deleteFolderExpandKeyIfRedundant(oldName, otherScriptNames, script.name);
            }

            savedScriptDetails$.value[scriptKey] = {
                content: savedScriptDetails$.value[scriptKey]?.content ?? '',
                name: script.name,
                // TODO: set last modified date and by whom
            };

            scriptNameChangedAction$.next(scriptUid);
            closeEditScriptNameDialogAction$.next();
        } catch (e) {
            if (e instanceof InformativeError) {
                editScriptNameDialogErrors$.next(`Failed to rename script: ${script.name}. Reason: ${e.message}`);
            } else {
                editScriptNameDialogErrors$.next(
                    `Failed to rename script: ${script.name}, try saving the script again, if the issue persists please contact support.`
                );
                console.error('Error while renaming script', e);
            }
        }

        editScriptNameDialogLoading$.next(false);
    }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const triggerScript = async (environmentUid: string, scriptUid: string): Promise<void> => {
    try {
        updateScriptExecutionStatus(scriptUid, true);

        if (!selectedWorkspaceEnvironment$.value?.deployment) {
            if (featureFlagsTopic$.value.reduceManualTriggerBundling) {
                const scriptHasUnsavedChanges = scriptHasUnsavedChanges$.value;

                const scriptUids = [
                    ...new Set([
                        ...selectedWorkspaceResources$.value.scripts.map((script) => script.uid),
                        ...[scriptUid],
                    ]),
                ];
                const hasUnsavedScripts = scriptUids.some((script) => scriptHasUnsavedChanges[script]);

                const bundledScripts = selectedWorkspaceManualBundledScripts$.value;
                if (selectedWorkspaceManualScriptTriggerBundle$.value || !bundledScripts.includes(scriptUid)) {
                    await saveScriptBundledOutput(scriptUid, [], true);
                    if (!hasUnsavedScripts) {
                        selectedWorkspaceManualScriptTriggerBundle$.next(false);
                    }
                    selectedWorkspaceManualBundledScripts$.next([...new Set([...bundledScripts, scriptUid])]);
                } else if (hasUnsavedScripts) {
                    await saveScriptBundledOutput(scriptUid, [], true);
                    selectedWorkspaceManualScriptTriggerBundle$.next(true);
                    selectedWorkspaceManualBundledScripts$.next([...new Set([...bundledScripts, scriptUid])]);
                }
            } else {
                await saveScriptBundledOutput(scriptUid, [], true);
            }
        }

        await executeScript(environmentUid, scriptUid);
        //TODO: need to redirect to feedback/console later
    } catch (e) {
        if (e instanceof InformativeError) {
            if (!(e instanceof CompilationError || e instanceof BundlingError)) {
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: e.message,
                    noToast: true,
                });
            }
        } else {
            console.error('Error while executing script', e);
            publishLocalFeedbackEventAction$.next({
                level: 'ERROR',
                message: `Failed to execute script, try executing the script again, if the issue persists please contact support.`,
                noToast: true,
            });
        }
    }

    updateScriptExecutionStatus(scriptUid, false);
};

const deleteFolderExpandKeyIfRedundant = (name: string, otherScriptNames: string[], newName?: string): void => {
    const splitPath = name.split('/');
    splitPath.pop();

    if (splitPath.length) {
        const directory = splitPath.join('/') + '/';
        const hasSiblings = otherScriptNames.some((sn) => sn.startsWith(directory));
        const nameStartsWithDirectory = !!newName?.startsWith(directory);

        if (!hasSiblings && !nameStartsWithDirectory) {
            const deletedKey = `isExpanded-${selectedWorkspaceUid$.value}-${directory}`;
            localStorage.removeItem(deletedKey);

            if (splitPath.length > 1) {
                deleteFolderExpandKeyIfRedundant(splitPath.join('/'), otherScriptNames, newName);
            }
        }
    }
};

export const storedScriptsPosition$ = monitor(
    'storedScriptsPosition$',
    new BehaviorSubject<Map<string, Position> | undefined>(new Map())
);
export const storeScriptPositionAction$ = monitor('storeScriptPositionAction$', new Subject<ScriptPosition>());

storeScriptPositionAction$.subscribe(({ key, position }) => {
    const value = storedScriptsPosition$.value;
    if (value) {
        value.set(key, position);
        storedScriptsPosition$.next(value);
    }
});

export const bundleScriptWhenEventIsEmitted = (observable: Observable<{ uid: string }>): void => {
    observable
        .pipe(
            map(async ({ uid }) => {
                const bundleScriptIfTypesAreLoaded = async (): Promise<void> => {
                    try {
                        if (currentTypeDeclarationsLoaded$.value === totalTypeDeclarationsLoaded$.value) {
                            await saveScriptBundledOutput(uid, []);
                        } else {
                            setTimeout(wrapAsync(bundleScriptIfTypesAreLoaded), 100);
                        }
                    } catch (e) {
                        console.error(`Failed to try to bundle newly created script: ${uid}`, e);
                    }
                };

                void bundleScriptIfTypesAreLoaded();
            })
        )
        .subscribe();
};

export const getUnsavedScriptCached = async (scriptUid: string, environmentUid?: string): Promise<Script> => {
    const scriptKey = `${scriptUid}_${environmentUid ?? ''}`;
    let script = unsavedScriptDetails$.value[scriptKey];

    if (!script) {
        script = await getScript(scriptUid, environmentUid);

        savedScriptDetails$.next({ ...savedScriptDetails$.value, [scriptKey]: script });
        unsavedScriptDetails$.next({ ...unsavedScriptDetails$.value, [scriptKey]: script });
    }

    return script;
};

export const getUnsavedScriptWithUidCached = async (
    scriptUid: string,
    environmentUid?: string
): Promise<ScriptWithUid> => {
    const script = await getUnsavedScriptCached(scriptUid, environmentUid);

    return {
        ...script,
        uid: scriptUid,
    };
};

export const getScriptFromUrlWithUidCached = async (
    { uid, name, lastModifiedBy, lastModifiedDate, signedUrl }: ScriptWithUrl,
    environmentUid?: string
): Promise<ScriptWithUid> => {
    const scriptKey = `${uid}${environmentUid ? '_' : ''}${environmentUid}`;
    let script = unsavedScriptDetails$.value[scriptKey];

    if (!script) {
        const content = await (
            await refetch(signedUrl, {
                retryDelay: 100,
                retries: 5,
                afterFetch: async (_time, response) => {
                    if (!response?.ok) {
                        publishLocalFeedbackEventAction$.next({
                            level: 'ERROR',
                            message: `${name} could not be loaded. Please reload the page, if the issue persists please contact support`,
                            noToast: true,
                        });
                    }
                },
            })
        ).text();
        script = {
            name,
            lastModifiedBy,
            lastModifiedDate,
            content,
        };

        savedScriptDetails$.next({ ...savedScriptDetails$.value, [scriptKey]: script });
        unsavedScriptDetails$.next({ ...unsavedScriptDetails$.value, [scriptKey]: script });
    }

    return {
        ...script,
        uid,
    };
};

export const getScriptsSignedUrls = async (
    scriptUids: string[],
    workspaceUid?: string,
    environmentUid?: string
): Promise<ScriptsWithUrl> => {
    if (!workspaceUid) {
        console.debug('Request to load scripts without a selected workspace so discarding');
        return [];
    }
    return await getScripts(scriptUids, workspaceUid, environmentUid);
};

bundleScriptWhenEventIsEmitted(newScriptCreatedAction$);

import { BehaviorSubject, map, Subject } from 'rxjs';
import { segmentAnalyticsTrack } from '../data/segment-analytics';
import { assumeWorkspaceEditControl } from '../data/workspace';
import {
    createWorkspace,
    CreateWorkspaceResponse,
    CreateWorkspaceRequest,
    UserWorkspaces,
    deleteWorkspace,
    updateWorkspaceDetails,
    getSaveWorkspaceFormDetails,
    UpdateWorkspaceDetailsResponse,
} from '../data/workspaces';
import { getBundledOutputsFromSelectedWorkspace } from '../utils/bundler';
import { InformativeError, PermissionError } from '../utils/error';
import { publishLocalFeedbackEventAction$ } from './feedback';
import { monitor } from './monitor';
import { selectedReadOnlyTemplate$ } from './templates';
import {
    ignoreWorkspaceExitUnsavedChangesCheck$,
    selectedWorkspace$,
    selectedWorkspaceReadOnlyMode$,
    selectedWorkspaceResources$,
    selectedWorkspaceUid$,
} from './workspace';
import { loadWorkspaces } from './workspace/utils';
import { promptQuestion } from './confirm';
import { ITIPPCS } from '../i18n';
import { loggedInUserDetails$ } from './user';
import { scriptsBeingSaved$ } from './workspace/script';
import { saveBlankWorkspaceCorePackagesAction$ } from './workspace/packages';
import { selectedOrganizationUid$, switchOrganizationAction$ } from './organization';
import { SetupGuideType } from '@avst-stitch/repository-lib/lib/models';
import { TemplateCategory } from '../components/workspace-dialogs/EditWorkspaceDialog';

export interface NewCopiedWorkspaceDetails {
    description?: string;
    name: string;
    setupGuide?: SetupGuideType;
    sourceUid?: string;
}

interface EditWorkspaceRequest {
    uid: string;
    name: string;
    description?: string;
    template: boolean;
    newTemplate?: boolean;
    createdFromTemplate?: boolean;
    organizationUid: string;
    useCaseUids: string[];
    complexityUid?: string;
    templateCustomId?: string;
}

interface WorkspaceFormDetails {
    defaultOrganizationUid?: string;
    selectedOrganizationUid?: string;
    organizations: {
        value: string;
        name: string;
    }[];
    organizationDropdownDisabled: boolean;
    isWorkspaceOwner?: boolean;
    useCases?: SelectableTemplateCategory[];
    complexities?: SelectableTemplateCategory[];
    templateCustomId?: string;
}

interface SelectableTemplateCategory extends TemplateCategory {
    selected: boolean;
}

export const createWorkspaceFromTemplateAction$ = monitor('createWorkspaceFromTemplateAction$', new Subject<void>());

export const newWorkspaceFromTemplate$ = monitor(
    'newWorkspaceFromTemplate$',
    new BehaviorSubject<NewCopiedWorkspaceDetails | undefined>(undefined)
);

export const newDuplicatedWorkspace$ = monitor(
    'newDuplicatedWorkspace$',
    new BehaviorSubject<NewCopiedWorkspaceDetails | undefined>(undefined)
);

export const openNewBlankWorkspaceAction$ = monitor(
    'newBlankWorkspaceAction$',
    new Subject<'blank' | 'guided' | 'home' | 'template' | 'duplicated'>()
);
export const newBlankWorkspaceSource$ = monitor(
    'newBlankWorkspaceSource$',
    new BehaviorSubject<'blank' | 'guided' | 'home' | 'template' | 'duplicated' | undefined>(undefined)
);
export const saveNewBlankWorkspaceAction$ = monitor(
    'saveNewBlankWorkspaceAction$',
    new Subject<Omit<CreateWorkspaceRequest, 'source'>>()
);
export const cancelNewBlankWorkspaceAction$ = monitor('cancelNewBlankWorkspaceAction$', new Subject<void>());
export const cancelEditWorkspaceAction$ = monitor('cancelEditWorkspaceAction$', new Subject<void>());
export const workspaceCreatedAction$ = monitor('workspaceCreatedAction$', new Subject<CreateWorkspaceResponse>());
export const deleteWorkspaceAction$ = monitor('deleteWorkspaceAction$', new Subject<string>());
export const editWorkspaceAction$ = monitor('editWorkspaceAction$', new Subject<EditWorkspaceRequest>());
export const updateWorkspaceDetailsAction$ = monitor(
    'updateWorkspaceDetailsAction$',
    new Subject<EditWorkspaceRequest>()
);
export const workspaceDeletedAction$ = monitor('workspaceDeletedAction$', new Subject<string>());
export const assumeWorkspaceEditControlAction$ = monitor('assumeWorkspaceEditControlAction$', new Subject<boolean>());
export const workspaceEditControlAssumedAction$ = monitor('workspaceEditControlAssumedAction$', new Subject<void>());
export const workspaceEditControlAssumedForReplayInvocationAction$ = monitor(
    'workspaceEditControlAssumedForReplayInvocationAction$',
    new Subject<void>()
);

export const loggedInUserWorkspaces$ = monitor('loggedInUserWorkspaces$', new BehaviorSubject<UserWorkspaces>([]));
export const newBlankWorkspaceDialogOpen$ = monitor('newBlankWorkspaceOpen$', new BehaviorSubject(false));
export const savingNewBlankWorkspace$ = monitor('savingNewBlankWorkspace$', new BehaviorSubject(false));
export const openWorkspacePreviewDialogErrors$ = monitor(
    'openWorkspacePreviewDialogErrors$',
    new BehaviorSubject<string | undefined>(undefined)
);
export const workspacePreviewDialogOpen$ = monitor('workspacePreviewDialogOpen$', new BehaviorSubject(false));
export const bundleNewWorkspaceScriptsAction$ = monitor('bundleNewWorkspaceScriptsAction$', new Subject<void>());

export const editWorkspaceDialogOpen$ = monitor('editWorkspaceDialogOpen$', new BehaviorSubject(false));
export const workspaceBeingEditted$ = monitor(
    'workspaceBeingEditted$',
    new BehaviorSubject<EditWorkspaceRequest | undefined>(undefined)
);
export const savingExistingWorkspace$ = monitor('savingExistingWorkspace$', new BehaviorSubject(false));
export const workspaceFormDetails$ = monitor(
    'workspaceFormDetails$',
    new BehaviorSubject<WorkspaceFormDetails | undefined>(undefined)
);
export const getWorkspaceFormDetailsAction$ = monitor(
    'getWorkspaceFormDetailsAction$',
    new Subject<string | undefined>()
);
export const workspaceDetailsUpdatedAction$ = monitor('workspaceDetailsUpdatedAction$', new Subject<void>());
export const loadingWorkspaceFormDetails$ = monitor('loadingWorkspaceFormDetails$', new BehaviorSubject(false));

export const compileAndBundleWorkspaceScriptsAction$ = monitor(
    'compileAndBundleWorkspaceScriptsAction$',
    new Subject<void>()
);

createWorkspaceFromTemplateAction$.subscribe(() => {
    const selectedWorkspace = selectedWorkspace$.value;

    newDuplicatedWorkspace$.next(undefined);
    newWorkspaceFromTemplate$.next({
        name: selectedWorkspace?.name ?? '',
        description: selectedWorkspace?.description,
        sourceUid: selectedWorkspace?.templateUid,
    });
    openNewBlankWorkspaceAction$.next('template');
});

getWorkspaceFormDetailsAction$
    .pipe(
        map(async (uid) => {
            loadingWorkspaceFormDetails$.next(true);
            try {
                const response = await getSaveWorkspaceFormDetails(uid);

                workspaceFormDetails$.next({
                    selectedOrganizationUid: response.selectedOrganizationUid,
                    defaultOrganizationUid: response.defaultOrganizationUid,
                    organizations: response.organizations.map((org) => ({
                        name: org.name,
                        value: org.uid,
                    })),
                    organizationDropdownDisabled: response.organizationDropdownDisabled,
                    isWorkspaceOwner: response.isWorkspaceOwner,
                    useCases: response.useCases,
                    complexities: response.complexities,
                    templateCustomId: response.templateCustomId,
                });
            } catch (err) {
                console.error('Error while retrieving workspace form details', err);
                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message: `Failed to retrieve Workspace form details, please try again. ${ITIPPCS}`,
                    noToast: true,
                });
            }
            loadingWorkspaceFormDetails$.next(false);
        })
    )
    .subscribe();

bundleNewWorkspaceScriptsAction$
    .pipe(
        map(async () => {
            await getBundledOutputsFromSelectedWorkspace({ publishCompileError: false });
            if (!selectedWorkspaceReadOnlyMode$.value) {
                newDuplicatedWorkspace$.next(undefined);
                newWorkspaceFromTemplate$.next(undefined);
            }
        })
    )
    .subscribe();

openNewBlankWorkspaceAction$.subscribe((source) => {
    getWorkspaceFormDetailsAction$.next(undefined);
    newBlankWorkspaceDialogOpen$.next(true);
    savingNewBlankWorkspace$.next(false);
    openWorkspacePreviewDialogErrors$.next(undefined);
    newBlankWorkspaceSource$.next(source);
    if (source === 'blank' || source === 'guided') {
        segmentAnalyticsTrack('Setup Initiated', { type: source, userOrigin: loggedInUserDetails$.value?.userOrigin });
    }
});
cancelNewBlankWorkspaceAction$.subscribe(() => {
    newWorkspaceFromTemplate$.next(undefined);
    newDuplicatedWorkspace$.next(undefined);
    newBlankWorkspaceDialogOpen$.next(false);
    newBlankWorkspaceSource$.next(undefined);
});

saveNewBlankWorkspaceAction$
    .pipe(
        map(async ({ name, description, template, templateUid, sourceWorkspaceUid, setupGuide, organizationUid }) => {
            try {
                savingNewBlankWorkspace$.next(true);
                openWorkspacePreviewDialogErrors$.next(undefined);

                //Extra checks to ensure we don't somehow create a template from a template
                const templateProps = {
                    template: !templateUid && template ? template : undefined,
                    templateUid: templateUid && !template ? templateUid : undefined,
                };

                const source = newBlankWorkspaceSource$.value ?? 'home';

                const workspace = await createWorkspace({
                    name,
                    description,
                    source,
                    organizationUid,
                    sourceWorkspaceUid,
                    setupGuide,
                    ...templateProps,
                });

                if (source === 'blank') {
                    segmentAnalyticsTrack('Setup Finished', {
                        type: source,
                        userOrigin: loggedInUserDetails$.value?.userOrigin,
                    });
                }

                newBlankWorkspaceDialogOpen$.next(false);
                workspaceCreatedAction$.next(workspace);
                publishLocalFeedbackEventAction$.next({
                    level: 'SUCCESS',
                    message: 'Workspace created.',
                });
                selectedReadOnlyTemplate$.next(undefined);

                if (!sourceWorkspaceUid && !templateProps.templateUid) {
                    saveBlankWorkspaceCorePackagesAction$.next({ workspaceUid: workspace.workspaceUid });
                }

                await loadWorkspaces(organizationUid);
            } catch (e) {
                savingNewBlankWorkspace$.next(false);
                if (e instanceof InformativeError) {
                    openWorkspacePreviewDialogErrors$.next(e.message);
                } else {
                    openWorkspacePreviewDialogErrors$.next(
                        `Unknown error occurred while creating a new workspace, please try again. ${ITIPPCS}`
                    );
                    console.error('Error while creating new workspace', e);
                }
            }
        })
    )
    .subscribe();

deleteWorkspaceAction$
    .pipe(
        map(async (uid) => {
            promptQuestion({
                title: 'Deleting the Workspace will also delete all of the associated data in the record storage. Are you sure you want to delete the Workspace?',
                onProceed: async () => {
                    try {
                        await deleteWorkspace(uid);

                        publishLocalFeedbackEventAction$.next({
                            level: 'SUCCESS',
                            message: 'Workspace deleted.',
                        });

                        workspaceDeletedAction$.next(uid);
                        deleteWorkspaceLocalStorageKeys(uid);
                    } catch (e) {
                        if (e instanceof InformativeError) {
                            publishLocalFeedbackEventAction$.next({
                                level: 'ERROR',
                                message: e.message,
                                toastOptions: {
                                    autoClose: false,
                                },
                            });
                        } else {
                            console.error('Error while deleting workspace', e);

                            publishLocalFeedbackEventAction$.next({
                                level: 'ERROR',
                                message: `Failed to delete Workspace, please try again. ${ITIPPCS}`,
                                noToast: true,
                            });
                        }
                    }
                },
            });
        })
    )
    .subscribe();

editWorkspaceAction$.subscribe((details) => {
    if (details) {
        workspaceBeingEditted$.next(details);
        savingExistingWorkspace$.next(false);
        editWorkspaceDialogOpen$.next(true);
        openWorkspacePreviewDialogErrors$.next(undefined);
        getWorkspaceFormDetailsAction$.next(details.uid);
    }
});
cancelEditWorkspaceAction$.subscribe(() => {
    workspaceBeingEditted$.next(undefined);
    openWorkspacePreviewDialogErrors$.next(undefined);
    savingExistingWorkspace$.next(false);
    editWorkspaceDialogOpen$.next(false);
    workspaceFormDetails$.next(undefined);
});

updateWorkspaceDetailsAction$
    .pipe(
        map(async (details) => {
            try {
                const callUpdateWorkspaceDetails = async (): Promise<UpdateWorkspaceDetailsResponse> => {
                    savingExistingWorkspace$.next(true);
                    const updatedWorkspaceDetails = await updateWorkspaceDetails({
                        ...details,
                        newTemplate: details.newTemplate ?? false,
                    });
                    publishLocalFeedbackEventAction$.next({
                        level: 'SUCCESS',
                        message: 'Workspace details updated.',
                    });
                    editWorkspaceDialogOpen$.next(false);

                    return updatedWorkspaceDetails;
                };

                const updatedWorkspaceDetails = await callUpdateWorkspaceDetails();
                savingExistingWorkspace$.next(false);
                selectedWorkspace$.next({
                    ...updatedWorkspaceDetails,
                    locked: selectedWorkspace$.value?.locked ?? undefined,
                    draft: details.template,
                });
                workspaceDetailsUpdatedAction$.next();
                if (details.organizationUid !== selectedOrganizationUid$.value) {
                    switchOrganizationAction$.next(details.organizationUid);
                }
                await loadWorkspaces(details.organizationUid);
            } catch (e) {
                savingExistingWorkspace$.next(false);

                if (e instanceof InformativeError || e instanceof PermissionError) {
                    openWorkspacePreviewDialogErrors$.next(e.message);
                } else {
                    console.error('Error while updating workspace details', e);
                    openWorkspacePreviewDialogErrors$.next(
                        `Unknown error occurred while updating workspace details, please try again. ${ITIPPCS}`
                    );
                }
            }
        })
    )
    .subscribe();

assumeWorkspaceEditControlAction$
    .pipe(
        map(async (isReplayInvocationMode) => {
            try {
                promptQuestion({
                    cancelLabel: 'Cancel',
                    icon: 'warning',
                    messages: [
                        'Workspace is currently in read-only mode. Unlocking the workspace will exit the other session and reload the workspace with the latest data.',
                    ],
                    proceedLabel: 'Confirm unlock',
                    subtitle: 'This action may affect other users.',
                    title: 'Are you sure you want to unlock workspace?',
                    onProceed: async () => {
                        await assumeWorkspaceEditControl(selectedWorkspaceUid$.value ?? '');
                        ignoreWorkspaceExitUnsavedChangesCheck$.next(true);
                        if (isReplayInvocationMode) {
                            workspaceEditControlAssumedForReplayInvocationAction$.next();
                        } else {
                            workspaceEditControlAssumedAction$.next();
                        }
                    },
                });
            } catch (e) {
                if (e instanceof InformativeError) {
                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: e.message,
                    });
                } else {
                    console.error('Error while assuming workspace edit control', e);

                    publishLocalFeedbackEventAction$.next({
                        level: 'ERROR',
                        message: `Failed to assume workspace edit control, please try again. ${ITIPPCS}`,
                        toastOptions: {
                            autoClose: false,
                        },
                    });
                }
            }
        })
    )
    .subscribe();

const deleteWorkspaceLocalStorageKeys = (uid: string): void => {
    const storageKeys = Object.keys(localStorage);
    const environmentVariablesReadmeOpenKey = storageKeys.find((k) => k === `isEnvironmentVariablesReadmeOpen-${uid}`);
    const environmentVariablesReadmeFlexGrowKey = storageKeys.find(
        (k) => k === `environmentVariablesReadmeFlexGrow-${uid}`
    );
    const folderExpandKeys = storageKeys.filter((k) => k.includes(`isExpanded-${uid}-`));

    if (environmentVariablesReadmeFlexGrowKey) {
        localStorage.removeItem(environmentVariablesReadmeFlexGrowKey);
    }
    if (environmentVariablesReadmeOpenKey) {
        localStorage.removeItem(environmentVariablesReadmeOpenKey);
    }

    for (const key of folderExpandKeys) {
        localStorage.removeItem(key);
    }
};

compileAndBundleWorkspaceScriptsAction$
    .pipe(
        map(async () => {
            const workspaceScripts = selectedWorkspaceResources$.value.scripts;

            scriptsBeingSaved$.next({
                ...scriptsBeingSaved$.value,
                ...Object.fromEntries(workspaceScripts.map((sc) => [sc.uid, true])),
            });

            await getBundledOutputsFromSelectedWorkspace();

            scriptsBeingSaved$.next({
                ...scriptsBeingSaved$.value,
                ...Object.fromEntries(workspaceScripts.map((sc) => [sc.uid, false])),
            });
        })
    )
    .subscribe();

import { LoaderFn } from 'react-location';
import { getLoggedInUserDetailsAndInitialize } from '../data/user';
import { LocationGenerics } from '../router';
import { acceptInviteAction$, loggedInUserDetails$ } from '../store/user';
import { promptMessage } from '../store/confirm';
import { isAccountVerified } from '../utils/account';
import { FullUserDetails } from '../utils/auth';
import { publishLocalFeedbackEventAction$ } from '../store/feedback';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const appLoader: LoaderFn<LocationGenerics> = async () => {
    const verified = new URLSearchParams(window.location.search).get('verified');
    const inviteId = new URLSearchParams(window.location.search).get('inviteId');
    if (verified) {
        if (verified === 'true') {
            try {
                const { accountVerified, acceptedOrganizationInvites } = await isAccountVerified();
                if (accountVerified) {
                    const userDetails = await getLoggedInUserDetailsAndInitialize();
                    loggedInUserDetails$.next(userDetails);

                    if (acceptedOrganizationInvites?.length) {
                        if (acceptedOrganizationInvites.length === 1) {
                            promptMessage({ title: `Successfully joined team: ${acceptedOrganizationInvites[0]}` });
                        } else {
                            promptMessage({
                                title: `Successfully joined teams: ${acceptedOrganizationInvites.join(', ')}`,
                            });
                        }
                    }
                } else {
                    loggedInUserDetails$.next({
                        accountVerified: false,
                    } as FullUserDetails);
                }
            } catch (error) {
                console.error('Error whilst checking if user account is verified', error);

                publishLocalFeedbackEventAction$.next({
                    level: 'ERROR',
                    message:
                        `Could not verify email account. Click on the email verification link again ` +
                        'and if the issue persists please contact support.',
                    toastOptions: {
                        autoClose: false,
                    },
                });
            }
        } else {
            loggedInUserDetails$.next({
                accountVerified: false,
            } as FullUserDetails);
        }
    } else if (inviteId && loggedInUserDetails$.value?.accountVerified) {
        //Keep this for social login where verification is not required
        acceptInviteAction$.next(inviteId);
    }

    return {};
};

/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import { LinkProps } from 'react-location';
import { styled } from '@mui/material';
import { ThemeMode } from '../../../theme';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { SidenavOrganizationSelector } from './SideNavOrganizationSelector';
import { SidenavOrganizationSettingsNavigator } from './SidenavOrganizationSettingsNavigator';
import { SidenavReportingNavigator } from './SidenavReportingNavigator';
import { SidenavThemeModeSelector } from './SidenavThemeModeSelector';
import { SidenavUserSettingsNavigator } from './SidenavUserSettingsNavigator';
import { SRConnectIcon } from '../../icons/SRConnectIcon';
import { StyledListItemButton } from './SidenavComponents';
import { SidenavOrganization } from '@avst-stitch/repository-lib/lib/rpcs/getMyDetails';
import { UserNameDetails } from '../AppMain';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import { InformationChip } from '../../common/chips/InformationChip';
import { RouterLink } from '../../common/RouterLink';

export type SidenavPath =
    | 'Home'
    | 'Connectors'
    | 'Workspaces'
    | 'Reporting'
    | 'Templates'
    | 'Upgrade'
    | 'Organizations';

interface SidenavProps {
    basePath: string;
    displayApiKeys: boolean;
    helpAndSupportOpen?: boolean;
    isLogOutBusy?: boolean;
    organizations?: SidenavOrganization[];
    selectedOrganizationUid?: string;
    selectedPath: string;
    showUpgradePlan?: boolean;
    themeMode: ThemeMode;
    userCredentials: UserNameDetails;
    useRouter?: boolean;
    aiAssistantOpen?: boolean;
    onChangeThemeMode(mode: ThemeMode): void;
    onCreateNewOrganization(): void;
    onLogOut(): void;
    onManageAllOrganizations(): void;
    onOpenHelpAndSupport(): void; // Design indicates navigation to a page, but existing solution is a modal- further changes have to be coordinated with the Monday team
    onToggleAiAssistant(): void;
    onSelectOrganization(uid: string): void;
}

const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{ collapsed: boolean }>(
    ({ collapsed, theme }) => ({
        '& .MuiPaper-root': {
            position: 'relative',
            transition: theme.transitions.create(['width', 'justify-content'], {
                easing: theme.transitions.easing.sharp,
                duration: 200,
            }),
            width: collapsed ? 57 : 211,
        },
    })
);

const StyledDrawerHeader = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
}));

const StyledDrawerHeaderTop = styled(Box, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
    collapsed: boolean;
}>(({ collapsed, theme }) => ({
    ...theme.typography.flexAlignCenter,
    height: 42,
    justifyContent: collapsed ? 'center' : 'space-between',
    marginBottom: theme.spacing(2),
    '&>.MuiBox-root': collapsed
        ? {
              display: 'none',
          }
        : undefined,
}));

const StyledAppName = styled(ButtonBase)<LinkProps & { component?: React.ElementType }>(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    gap: theme.spacing(1),
    textAlign: 'start',
    '& .MuiTypography-root': {
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: '19.2px', // In order to get the text to the app icon size
    },
    '& .MuiSvgIcon-root': {
        height: 42,
        width: 42,
    },
}));

const StyledCollapseButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'collapsed' })<{
    collapsed: boolean;
}>(({ collapsed, theme }) => ({
    '&.MuiButtonBase-root': {
        // Remove once icon button size is changed in the theme
        height: 36,
        width: 36,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
        height: 20,
        transform: collapsed ? undefined : 'rotate(180deg)',
        width: 20,
    },
}));

const StyledDrawerMain = styled(Box)(() => ({
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
}));

const StyledList = styled(List)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    height: 28,
    lineHeight: 1,
    padding: theme.spacing(1),
    textTransform: 'uppercase',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(0),
    '&:not(:last-of-type)': {
        marginBottom: theme.spacing(0.5),
    },
}));

export const Sidenav: React.FC<SidenavProps> = ({
    basePath,
    displayApiKeys,
    helpAndSupportOpen = false,
    isLogOutBusy,
    organizations,
    selectedOrganizationUid,
    selectedPath,
    showUpgradePlan,
    themeMode,
    userCredentials,
    useRouter = false,
    aiAssistantOpen = false,
    onChangeThemeMode,
    onCreateNewOrganization,
    onLogOut,
    onManageAllOrganizations,
    onOpenHelpAndSupport,
    onSelectOrganization,
    onToggleAiAssistant,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const homePath = '/dashboard';

    const selectedOrganization = organizations?.find((org) => org.uid === selectedOrganizationUid);

    return (
        <StyledDrawer collapsed={collapsed} variant="permanent">
            <StyledDrawerHeader>
                <StyledDrawerHeaderTop collapsed={collapsed}>
                    {!collapsed && (
                        <StyledAppName
                            component={RouterLink}
                            data-pendo="homeLogo"
                            to={useRouter ? basePath + homePath : null}
                        >
                            <SRConnectIcon />
                            <Typography>
                                ScriptRunner
                                <br />
                                Connect
                            </Typography>
                        </StyledAppName>
                    )}
                    <Tooltip title={collapsed ? 'Expand' : 'Collapse'}>
                        <StyledCollapseButton
                            aria-label={collapsed ? 'Expand side navigation' : 'Collapse side navigation'}
                            onClick={() => setCollapsed(!collapsed)}
                            collapsed={collapsed}
                        >
                            <KeyboardTabOutlinedIcon />
                        </StyledCollapseButton>
                    </Tooltip>
                </StyledDrawerHeaderTop>
                <SidenavOrganizationSelector
                    collapsed={collapsed}
                    organizations={organizations}
                    selectedOrganizationUid={selectedOrganizationUid}
                    onCreateNewOrganization={onCreateNewOrganization}
                    onManageAllOrganizations={onManageAllOrganizations}
                    onSelectOrganization={onSelectOrganization}
                />
            </StyledDrawerHeader>
            <StyledDrawerMain>
                <StyledList>
                    <StyledListItem>
                        <StyledListItemButton
                            className={collapsed ? 'collapsed' : undefined}
                            component={RouterLink}
                            data-pendo="home"
                            selected={selectedPath === 'dashboard'}
                            to={useRouter ? basePath + homePath : null}
                        >
                            <Tooltip title="Home">
                                <CottageOutlinedIcon />
                            </Tooltip>
                            <Typography>Home</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    <StyledListSubheader>{collapsed ? '' : 'Integrate'}</StyledListSubheader>
                    <StyledListItem>
                        <StyledListItemButton
                            className={collapsed ? 'collapsed' : undefined}
                            component={RouterLink}
                            data-pendo="connectors"
                            selected={selectedPath === 'connectors'}
                            to={useRouter ? basePath + '/connectors' : null}
                        >
                            <Tooltip title="Connectors">
                                <PowerOutlinedIcon />
                            </Tooltip>
                            <Typography>Connectors</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    <StyledListItem>
                        <StyledListItemButton
                            className={collapsed ? 'collapsed' : undefined}
                            component={RouterLink}
                            data-pendo={'workspaces'}
                            selected={selectedPath === 'workspaces'}
                            to={useRouter ? basePath + '/workspaces' : null}
                        >
                            <Tooltip title="Workspaces">
                                <Inventory2OutlinedIcon />
                            </Tooltip>
                            <Typography>Workspaces</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                    <StyledListItem>
                        <SidenavReportingNavigator
                            basePath={basePath}
                            collapsed={collapsed}
                            data-pendo="reporting"
                            isSelected={selectedPath === 'reporting' || selectedPath === 'auditLogs'}
                            useRouter={useRouter}
                        />
                    </StyledListItem>
                    <StyledListSubheader>{collapsed ? '' : 'Explore'}</StyledListSubheader>
                    <StyledListItem>
                        <StyledListItemButton
                            className={collapsed ? 'collapsed' : undefined}
                            component={RouterLink}
                            data-pendo="templates"
                            selected={selectedPath === 'templates'}
                            to={useRouter ? basePath + '/templates' : null}
                        >
                            <Tooltip title="Templates">
                                <LocalLibraryOutlinedIcon />
                            </Tooltip>
                            <Typography>Templates</Typography>
                        </StyledListItemButton>
                    </StyledListItem>
                </StyledList>
                <Box>
                    <StyledList>
                        <StyledListItem>
                            <StyledListItemButton
                                className={collapsed ? 'collapsed' : undefined}
                                onClick={onToggleAiAssistant}
                                selected={aiAssistantOpen}
                                data-pendo={'aiAssistantAccessed'}
                            >
                                <Tooltip title="AI assistant">
                                    <AssistantOutlinedIcon />
                                </Tooltip>
                                <Typography>AI assistant</Typography>
                                {!collapsed && <InformationChip label="NEW" severity="success" size="small" />}
                            </StyledListItemButton>
                        </StyledListItem>
                        <StyledListItem>
                            <StyledListItemButton
                                onClick={onOpenHelpAndSupport}
                                selected={helpAndSupportOpen}
                                data-pendo="helpAccessed"
                            >
                                <Tooltip title="Help & support">
                                    <HelpOutlineOutlinedIcon />
                                </Tooltip>
                                <Typography>Help & support</Typography>
                            </StyledListItemButton>
                        </StyledListItem>
                        <StyledListSubheader>{collapsed ? '' : 'Settings'}</StyledListSubheader>
                        {showUpgradePlan && (
                            <StyledListItem>
                                <StyledListItemButton
                                    className={collapsed ? 'collapsed' : undefined}
                                    component={RouterLink}
                                    data-pendo="upgradeTeam"
                                    selected={false}
                                    to={useRouter ? basePath + `/team/${selectedOrganizationUid}/plan` : null}
                                >
                                    <Tooltip title="Upgrade plan">
                                        <ArrowCircleUpOutlinedIcon />
                                    </Tooltip>
                                    <Typography>Upgrade plan</Typography>
                                </StyledListItemButton>
                            </StyledListItem>
                        )}
                        {selectedOrganization && (
                            <StyledListItem>
                                <SidenavOrganizationSettingsNavigator
                                    basePath={basePath}
                                    collapsed={collapsed}
                                    isSelected={selectedPath === 'team'}
                                    organizationName={selectedOrganization.name}
                                    organizationUid={selectedOrganizationUid ?? ''}
                                    ownedDefaultOrganization={selectedOrganization.ownedDefaultOrganization}
                                    useRouter={useRouter}
                                />
                            </StyledListItem>
                        )}
                        <StyledListItem>
                            <SidenavThemeModeSelector
                                collapsed={collapsed}
                                selectedThemeMode={themeMode}
                                onSelectThemeMode={onChangeThemeMode}
                            />
                        </StyledListItem>
                    </StyledList>
                    <SidenavUserSettingsNavigator
                        basePath={basePath}
                        collapsed={collapsed}
                        displayApiKeys={displayApiKeys}
                        isLogOutBusy={isLogOutBusy}
                        userCredentials={userCredentials}
                        useRouter={useRouter}
                        onLogOut={onLogOut}
                    />
                </Box>
            </StyledDrawerMain>
        </StyledDrawer>
    );
};

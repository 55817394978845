import { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { EmptyState } from '../../common/EmptyState';
import { EnvironmentVariable } from '../../workspace-resources/environment-variables/EnvironmentVariable';
import { SearchField } from '../../common/inputs/custom/SearchField';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import { StyledBorderBox, StyledBorderBoxContent, StyledBorderBoxHeader } from '../../common/LayoutComponents';
import { StyledMainActions, StyledStepContainer } from '../SetupGuideComponents';
import {
    ChangeVariableTypeEvent,
    DeleteVariableEvent,
    FullEnvironmentVariable,
    ToggleVariableEditModeEvent,
    ToggleVariableExpandEvent,
    UpdateVariableEvent,
} from '../../workspace-resources/environment-variables/types';
import { documentationUrl } from '../../../utils/documentation';
import { filterVariablesBySearchTerm } from '../../workspace-resources/environment-variables/utils';

interface SetupGuideApiHandlerScreenProps {
    environmentVariables?: FullEnvironmentVariable[];
    errors?: string;
    nextStepName?: string;
    saving?: boolean;
    stepRequired?: boolean;
    templateMode?: boolean;
    variablesMissingInformation?: boolean;
    workspaceLocked?: boolean;
    onChangeVariableType: (event: ChangeVariableTypeEvent) => void;
    onContinue: () => void;
    onCreateVariable(parentId?: string): void;
    onCreateFolder: () => void;
    onDeleteVariable(event: DeleteVariableEvent): void;
    onGoBack: () => void;
    onSkip: () => void;
    onToggleVariableEditMode: (event: ToggleVariableEditModeEvent) => void;
    onToggleVariableExpand: (event: ToggleVariableExpandEvent) => void;
    onUpdateVariable: (event: UpdateVariableEvent) => void;
}

export const StyledSecondaryHeaderActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
}));

export const SetupGuideEnvironmentVariableScreen: React.FC<SetupGuideApiHandlerScreenProps> = ({
    environmentVariables = [],
    errors,
    nextStepName,
    saving = false,
    stepRequired = false,
    templateMode = false,
    variablesMissingInformation = false,
    workspaceLocked = false,
    onChangeVariableType,
    onContinue,
    onCreateVariable,
    onCreateFolder,
    onDeleteVariable,
    onGoBack,
    onSkip,
    onToggleVariableEditMode,
    onToggleVariableExpand,
    onUpdateVariable,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const setupComplete = !variablesMissingInformation && (!templateMode ? environmentVariables.length > 0 : true); // TODO: Validate requirements for blank workspace flow

    const handleCreateFolder = (): void => {
        setSearchTerm('');
        onCreateFolder();
    };

    const handleCreateVariable = (parentId?: string): void => {
        setSearchTerm('');
        onCreateVariable(parentId);
    };

    const paragraphs = [
        'Parameters provide a way to separate configuration from your scripts. You can declare these configuration values and then reference the corresponding parameter keys in your code.',
        'Using parameters instead of hardcoding configuration details that vary across different environments allows for more maintainable and modular code.',
    ];

    const filteredVariables = filterVariablesBySearchTerm(environmentVariables, searchTerm);

    const nextStepPendoAttribute = nextStepName === 'Scripts' ? 'progressToScripts' : undefined;

    const actions = (
        <>
            <Button variant="outlined" onClick={onGoBack}>
                Back
            </Button>
            {stepRequired ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={!setupComplete || workspaceLocked}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : setupComplete ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    onClick={onContinue}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : (
                <Button
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    variant="outlined"
                    onClick={onSkip}
                >
                    {nextStepName ? `Skip to ${nextStepName}` : 'Skip'}
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox
                paragraphs={paragraphs}
                title="What is a parameter?"
                watchVideoPendoTag="template-setup-parameters-demo"
            />
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        Parameters
                    </Typography>
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxHeader>
                    <StyledSecondaryHeaderActions flexGrow={1}>
                        <SearchField
                            disabled={environmentVariables.length < 2}
                            placeholder="Search by key name"
                            size="small"
                            searchTerm={searchTerm}
                            onChangeSearchTerm={(e) => setSearchTerm(e.target.value)}
                        />
                    </StyledSecondaryHeaderActions>
                    {!templateMode && (
                        <StyledSecondaryHeaderActions>
                            <Button
                                disabled={workspaceLocked}
                                startIcon={<CreateNewFolderOutlinedIcon />}
                                variant="outlined"
                                onClick={handleCreateFolder}
                            >
                                Folder
                            </Button>
                            <Button
                                disabled={workspaceLocked}
                                startIcon={<TokenOutlinedIcon />}
                                onClick={() => handleCreateVariable()}
                            >
                                Parameter
                            </Button>
                        </StyledSecondaryHeaderActions>
                    )}
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {environmentVariables.length ? (
                        <>
                            {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
                            {variablesMissingInformation && (
                                <Alert
                                    severity="warning"
                                    text={
                                        <strong>
                                            One or more elements are incomplete. Please review the following:
                                        </strong>
                                    }
                                />
                            )}
                            {filteredVariables.map((ev) => {
                                return (
                                    <EnvironmentVariable
                                        key={ev.id}
                                        childVariables={ev.childVariables}
                                        choices={ev.choices}
                                        defaultValue={ev.defaultValue}
                                        description={ev.description}
                                        disabled={workspaceLocked}
                                        editMode={ev.editMode}
                                        expanded={ev.expanded}
                                        hasBeenEdited={ev.hasBeenEdited}
                                        id={ev.id}
                                        keyName={ev.keyName}
                                        passwordValueFilled={ev.passwordValueFilled}
                                        required={ev.required}
                                        sameLevelKeyNames={environmentVariables.map((es) => es.keyName ?? '')}
                                        setupGuideTemplateMode={templateMode}
                                        type={ev.type}
                                        value={ev.value}
                                        onAddVariable={(parentId) => onCreateVariable(parentId)}
                                        onChangeType={onChangeVariableType}
                                        onDelete={onDeleteVariable}
                                        onToggleEditMode={onToggleVariableEditMode}
                                        onToggleExpand={onToggleVariableExpand}
                                        onUpdate={onUpdateVariable}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <EmptyState
                            buttons={
                                !templateMode
                                    ? [
                                          <Button
                                              key="learn-more"
                                              variant="text"
                                              onClick={() => window.open(documentationUrl, '_blank')}
                                          >
                                              Learn more
                                          </Button>,
                                          <Button
                                              disabled={workspaceLocked}
                                              key="create-folder"
                                              variant="outlined"
                                              onClick={() => onCreateVariable()}
                                          >
                                              Create folder
                                          </Button>,
                                          <Button
                                              disabled={workspaceLocked}
                                              key="create-environment-variable"
                                              onClick={() => onCreateVariable()}
                                          >
                                              Create parameter
                                          </Button>,
                                      ]
                                    : undefined
                            }
                            icon={<TokenOutlinedIcon />}
                            iconState={templateMode ? 'complete' : 'incomplete'}
                            subtitle={
                                !templateMode
                                    ? 'Create a parameter to start building your integration.'
                                    : 'Click on Continue to proceed to the next step.'
                            }
                            title={templateMode ? 'No parameters in this template' : 'No parameters found'}
                        />
                    )}
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};

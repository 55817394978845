import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Users, UsersReportRequest } from '../../data/report/users';
import { PageContainer } from '../app-main/PageComponentsForDeprecation';

export interface UsersReportPageProps extends UsersReportRequest {
    users: Users;
    onSearch(
        email: string,
        emailComparator: string,
        workspaces: number,
        workspacesComparator: string,
        connections: number,
        connectionsComparator: string,
        orderByField: string,
        orderByDirection: string
    ): void;
    onImpersonate(impersonateeUserUid: string): void;
}

export const UsersReportPage: React.FC<UsersReportPageProps> = ({
    users,
    email = '',
    emailComparator = 'equals',
    workspaces = -1,
    workspacesComparator = 'gt',
    connections = -1,
    connectionsComparator = 'gt',
    orderByField = 'email',
    orderByDirection = 'asc',
    onSearch,
    onImpersonate,
}) => {
    const [emailFilterValue, setEmailFilterValue] = useState('');
    const [emailFilterComparator, setEmailComparator] = useState('equals');
    const [workspacesFilterValue, setWorkspacesFilterValue] = useState(-1);
    const [workspacesFilterComparator, setWorkspacesFilterComparator] = useState('gt');
    const [connectionsFilterValue, setConnectionsFilterValue] = useState(-1);
    const [connectionsFilterComparator, setConnectionsFilterComparator] = useState('gt');
    const [orderByFieldValue, setOrderByFieldValue] = useState('email');
    const [orderByDirectionValue, setOrderByDirectionValue] = useState('asc');
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setEmailFilterValue(email);
        setEmailComparator(emailComparator);
        setWorkspacesFilterValue(workspaces);
        setWorkspacesFilterComparator(workspacesComparator);
        setConnectionsFilterValue(connections);
        setConnectionsFilterComparator(connectionsComparator);
        setOrderByFieldValue(orderByField);
        setOrderByDirectionValue(orderByDirection);
    }, []);

    const handleSearch = (): void =>
        onSearch(
            emailFilterValue,
            emailFilterComparator,
            workspacesFilterValue,
            workspacesFilterComparator,
            connectionsFilterValue,
            connectionsFilterComparator,
            orderByFieldValue,
            orderByDirectionValue
        );

    return (
        <PageContainer sx={{ bgcolor: 'background.paper' }}>
            <Box pb={3}>
                <Typography variant="h3">Users</Typography>
            </Box>
            <Box
                sx={{
                    '& .MuiFormControl-root': { m: 1 },
                }}
            >
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={emailFilterValue}
                            onChange={(event) => setEmailFilterValue(event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Email Comparator"
                            value={emailFilterComparator}
                            onChange={(event) => setEmailComparator(event.target.value)}
                        >
                            <MenuItem value="equals">Equals</MenuItem>
                            <MenuItem value="contains">Contains</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl size="small">
                        <TextField
                            label="Workspaces"
                            variant="outlined"
                            type="number"
                            value={workspacesFilterValue}
                            onChange={(event) => setWorkspacesFilterValue(+event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Workspaces Comparator"
                            value={workspacesFilterComparator}
                            onChange={(event) => setWorkspacesFilterComparator(event.target.value)}
                        >
                            <MenuItem value="gt">Greater Than</MenuItem>
                            <MenuItem value="lt">Lesser Than</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl>
                        <TextField
                            label="Connectors"
                            variant="outlined"
                            type="number"
                            value={connectionsFilterValue}
                            onChange={(event) => setConnectionsFilterValue(+event.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Connectors Comparator"
                            value={connectionsFilterComparator}
                            onChange={(event) => setConnectionsFilterComparator(event.target.value)}
                        >
                            <MenuItem value="gt">Greater Than</MenuItem>
                            <MenuItem value="lt">Lesser Than</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <div>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Order By"
                            value={orderByFieldValue}
                            onChange={(event) => setOrderByFieldValue(event.target.value)}
                        >
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="registeredDate">Registered Date</MenuItem>
                            <MenuItem value="lastAccessDate">Last Access Date</MenuItem>
                            <MenuItem value="workspaces">Workspaces</MenuItem>
                            <MenuItem value="connections">Connectors</MenuItem>
                        </TextField>
                    </FormControl>
                    <FormControl>
                        <TextField
                            select
                            variant="outlined"
                            label="Order By Direction"
                            value={orderByDirectionValue}
                            onChange={(event) => setOrderByDirectionValue(event.target.value)}
                        >
                            <MenuItem value="asc">Ascending</MenuItem>
                            <MenuItem value="desc">Descending</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
                <Box sx={{ paddingLeft: 2 }}>
                    <Typography>Total Users: {users.length}</Typography>
                </Box>
                <div>
                    <Button variant="contained" onClick={handleSearch} sx={{ m: 2 }}>
                        Search
                    </Button>
                </div>
            </Box>
            <Button onClick={() => setExpanded((prev) => !prev)}>{expanded ? 'Collapse' : 'Show all columns'}</Button>
            {users.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell sx={{ minWidth: 160 }}>Registered Date</TableCell>
                            <TableCell sx={{ minWidth: 160 }}>Last Access Date</TableCell>
                            <TableCell>Workspaces</TableCell>
                            <TableCell>Connectors</TableCell>
                            <TableCell>Teams</TableCell>
                            {expanded && (
                                <>
                                    <TableCell>Origin</TableCell>
                                    <TableCell>Referred From</TableCell>
                                    <TableCell>Campaign</TableCell>
                                    <TableCell>Medium</TableCell>
                                    <TableCell>Source</TableCell>
                                </>
                            )}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.uid} id={user.uid}>
                                <TableCell>{user.uid}</TableCell>
                                <TableCell>{user.firstName}</TableCell>
                                <TableCell>{user.lastName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{new Date(user.registeredDate).toLocaleString()}</TableCell>
                                <TableCell>
                                    {user.lastAccessDate ? new Date(user.lastAccessDate).toLocaleString() : ''}
                                </TableCell>
                                <TableCell>{user.workspaces}</TableCell>
                                <TableCell>{user.connections}</TableCell>
                                <TableCell>{user.teams}</TableCell>
                                {expanded && (
                                    <>
                                        <TableCell>{user.origin}</TableCell>
                                        <TableCell>{user.referrerSite}</TableCell>
                                        <TableCell>{user.utmCampaign}</TableCell>
                                        <TableCell>{user.utmMedium}</TableCell>
                                        <TableCell>{user.utmSource}</TableCell>
                                    </>
                                )}
                                <TableCell>
                                    <Button variant="text" onClick={() => onImpersonate(user.uid)}>
                                        Impersonate
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <div>No Users found</div>
            )}
        </PageContainer>
    );
};

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconCircle } from '../../common/IconCircle';
import { ProductIcon } from '../../icons/ProductIcon';
import { InformationChip } from '../../common/chips/InformationChip';
import { CustomIconButtonProps, IconButton } from '../../common/buttons/IconButton';
import { RouterLink } from '../../common/RouterLink';

export interface Template {
    apps?: string[];
    draft?: boolean;
    isNew?: boolean;
    name: string;
    uid: string;
}

export interface TemplateCardProps extends Template {
    devMode?: boolean;
    onSelect: (uid: string) => void;
}

interface LinkIconButtonProps extends CustomIconButtonProps {
    component?: React.ElementType;
    to?: string;
}

const LinkIconButton: React.FC<LinkIconButtonProps> = (props) => {
    return <IconButton {...props} />;
};

const StyledCard = styled(ButtonBase)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.constants.borderRadius,
    display: 'inline-block',
    textAlign: 'start',
    width: 250,
    '&:hover': {
        border: theme.constants.borderSelected,
    },
}));

const StyledContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 228,
    padding: theme.spacing(2),
}));

const StyledUpperContentContainer = styled(Box)(({ theme }) => ({
    height: 156,
    marginBottom: theme.spacing(1.5),
    overflow: 'hidden',
}));

const StyledIconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
}));

const StyledAppNames = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    marginBottom: theme.spacing(0.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    wordBreak: 'break-word',
}));

const StyledName = styled(Typography)(() => ({
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    wordBreak: 'break-word',
}));

const StyledChipContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
}));

const StyledActions = styled(Box)(({ theme }) => ({
    ...theme.typography.flexAlignCenter,
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
}));

export const TemplateSelectorCard: React.FC<TemplateCardProps> = ({
    apps = [],
    devMode = false,
    draft = false,
    isNew = false,
    name,
    uid,
    onSelect,
}) => {
    // We don't have more than 4 apps on any template at the moment, but for futureproofing...
    const icons = apps.slice(0, 4).map((app) => <IconCircle icon={<ProductIcon name={app} />} />);

    return (
        <StyledCard data-pendo={'templateViewed'} onClick={() => onSelect(uid)}>
            <StyledContentContainer>
                <StyledUpperContentContainer>
                    {icons.length > 0 && <StyledIconContainer>{icons}</StyledIconContainer>}
                    <Tooltip title={apps.join(' · ')}>
                        <StyledAppNames variant="body2" color="text.secondary">
                            {apps.join(' · ')}
                        </StyledAppNames>
                    </Tooltip>
                    <Tooltip title={name}>
                        <StyledName variant="subtitle2">{name}</StyledName>
                    </Tooltip>
                </StyledUpperContentContainer>
                <StyledChipContainer>
                    {isNew && <InformationChip label="New" severity="success" />}
                    {draft && <InformationChip label="Draft" severity="info" />}
                </StyledChipContainer>
            </StyledContentContainer>
            {devMode && (
                <>
                    <Divider />
                    <StyledActions>
                        <LinkIconButton
                            aria-label="Edit template"
                            border
                            component={RouterLink}
                            icon={<EditOutlinedIcon />}
                            to={`/editTemplate/${uid}`}
                            tooltip="Edit template"
                            onClick={(event) => event.stopPropagation()}
                        />
                    </StyledActions>
                </>
            )}
        </StyledCard>
    );
};

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { Alert } from '../../common/alerts/Alert';
import { Button } from '../../common/buttons/Button';
import { EmptyState } from '../../common/EmptyState';
import { IconCircle } from '../../common/IconCircle';
import { ProductIcon } from '../../icons/ProductIcon';
import { SetupGuideConnectionListItem } from './SetupGuideConnectionListItem';
import { SetupGuideSummaryBox } from '../SetupGuideSummaryBox';
import {
    StyledBorderBox,
    StyledBorderBoxContent,
    StyledBorderBoxHeader,
    StyledBorderBoxTitleContainer,
    StyledEmptyStateBox,
} from '../../common/LayoutComponents';
import { StyledMainActions, StyledStepContainer } from '../SetupGuideComponents';
import { SetupGuideAppInstance, SetupGuideConnection } from '../types';

export interface AppInstanceDetails {
    appName: string;
    group?: string;
}

export interface SetupGuideConnectionScreenProps {
    appInstances?: SetupGuideAppInstance[];
    errors?: string;
    nextStepName?: string;
    saving?: boolean;
    stepRequired?: boolean;
    workspaceLocked?: boolean;
    onChangeConnector: (event: AppInstanceDetails) => void;
    onContinue: (appInstances: SetupGuideAppInstance[]) => void;
    onCreateNewConnection: (event: AppInstanceDetails) => void;
    onEditConnection: (uid: string) => void;
    onGoBack: () => void;
}

const StyledAppInstanceListContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

export const SetupGuideConnectionScreen: React.FC<SetupGuideConnectionScreenProps> = ({
    appInstances = [],
    errors,
    nextStepName,
    saving = false,
    stepRequired = false,
    workspaceLocked = false,
    onChangeConnector,
    onContinue,
    onCreateNewConnection,
    onEditConnection,
    onGoBack,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const getSelectedConnection = (appInstance: SetupGuideAppInstance): SetupGuideConnection | undefined => {
        return appInstance.connections?.find((c) => c.uid === appInstance.selectedConnectionUid);
    };

    const resourcesNeedSetup = appInstances.some((ai) => {
        const selectedConnection = getSelectedConnection(ai);
        return selectedConnection && !selectedConnection.authorized;
    });

    const connectionsMissing = appInstances.some((ai) => !ai.selectedConnectionUid);
    const canSave = !connectionsMissing && !resourcesNeedSetup && !workspaceLocked;

    const paragraphs = [
        'When you create a connector, it contains all the authentication details for an API connection.',
        'You can reuse connectors across multiple workspaces, but you can only use your own connectors. This can help you increase the visibility of how connectors are being used.',
    ];

    const appList = appInstances.map((ai) => {
        const selectedConnection = getSelectedConnection(ai);
        const instanceName = ai.appName + (ai.group ? ` (${ai.group})` : '');
        return (
            <StyledAppInstanceListContainer key={instanceName}>
                <StyledBorderBoxTitleContainer>
                    <IconCircle icon={<ProductIcon name={ai.appName} />} />
                    <Typography variant="subtitle1">{instanceName}</Typography>
                </StyledBorderBoxTitleContainer>
                {selectedConnection ? (
                    <SetupGuideConnectionListItem
                        appName={ai.appName}
                        authorized={selectedConnection.authorized}
                        name={selectedConnection?.name}
                        uid={selectedConnection.uid}
                        workspaceLocked={workspaceLocked}
                        onEdit={onEditConnection}
                        onSwap={() => onChangeConnector({ appName: ai.appName, group: ai.group })}
                    />
                ) : (
                    <StyledEmptyStateBox>
                        <Button
                            data-pendo={'connectorCreationInitiated'}
                            disabled={workspaceLocked}
                            onClick={() => onCreateNewConnection({ appName: ai.appName, group: ai.group })}
                        >
                            Create connector
                        </Button>
                        {ai.connections?.length ? (
                            <Button
                                disabled={workspaceLocked}
                                variant="outlined"
                                onClick={() => onChangeConnector({ appName: ai.appName, group: ai.group })}
                            >
                                Use existing connector
                            </Button>
                        ) : null}
                    </StyledEmptyStateBox>
                )}
            </StyledAppInstanceListContainer>
        );
    });

    const nextStepPendoAttribute = nextStepName === 'API connections' ? 'progressToAPIConnections' : undefined;

    const actions = (
        <>
            <Button variant="outlined" onClick={onGoBack}>
                Back
            </Button>
            {stepRequired ? (
                <Button
                    data-pendo={nextStepPendoAttribute}
                    busy={saving}
                    disabled={!canSave}
                    onClick={() => onContinue(appInstances)}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : canSave ? (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    onClick={() => onContinue(appInstances)}
                >
                    {nextStepName ? `Continue to ${nextStepName}` : 'Complete setup'}
                </Button>
            ) : (
                <Button
                    busy={saving}
                    data-pendo={nextStepPendoAttribute}
                    disabled={workspaceLocked}
                    variant="outlined"
                    onClick={() => onContinue([])}
                >
                    {nextStepName ? `Skip to ${nextStepName}` : 'Skip'}
                </Button>
            )}
        </>
    );

    return (
        <StyledStepContainer>
            <SetupGuideSummaryBox
                paragraphs={paragraphs}
                title="What is a connector?"
                watchVideoPendoTag="template-setup-connectors-demo"
            />
            <StyledBorderBox>
                <StyledBorderBoxHeader>
                    <Typography variant="h6" component="h4">
                        Connectors
                    </Typography>
                </StyledBorderBoxHeader>
                <Divider />
                <StyledBorderBoxContent>
                    {errors && <Alert severity="error" text={<strong>{errors}</strong>} />}
                    {resourcesNeedSetup && (
                        <Alert
                            severity="warning"
                            text={<strong>One or more elements are incomplete. Please review the following:</strong>}
                        />
                    )}
                    {appList.length ? (
                        appList
                    ) : (
                        <EmptyState
                            icon={<PowerOutlinedIcon />}
                            iconState="complete"
                            subtitle="Click Continue to proceed to next step."
                            title="No connectors in this template"
                        />
                    )}
                </StyledBorderBoxContent>
                <Divider />
                <StyledMainActions>{actions}</StyledMainActions>
            </StyledBorderBox>
        </StyledStepContainer>
    );
};
